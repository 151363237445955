import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const containerStyle = `
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 16px 16px;
  border-radius: 16px;
`;

const Error = styled.p`
  background-color: #b9375e;
  ${containerStyle}
`;

const Success = styled.p`
  background-color: #2f7d31;
  ${containerStyle}
`;

const Info = styled.p`
  background-color: #0088d1;
  ${containerStyle}
`;

export enum AlertType {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
}

type AlertProps = {
  type: AlertType;
  children: ReactNode;
};

export const Alert: FC<AlertProps> = ({ children, type }) => {
  
  if (type == AlertType.SUCCESS) {
    return <Success>{children}</Success>;
  }

  if (type == AlertType.ERROR) {
    return <Error>{children}</Error>;
  }

  return <Info>{children}</Info>;
};
