import { Snackbar } from '@material-ui/core';
import { createContext, useContext, useState } from 'react';
import { Alert, AlertType } from './alert';

const initialValues = {
  snackbar: {
    open: false,
    message: '',
    type: AlertType.INFO,
  },
  showMessage: (message: string, type: AlertType) => {},
  handleClose: () => {},
};

const SnackbarContext = createContext(initialValues);

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

const MuiSnackbar = () => {
  const { snackbar, handleClose } = useSnackbar();
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={snackbar.open}
      autoHideDuration={5000}
      onClose={handleClose}
      key={'mui-snackbar'}
    >
      <Alert type={snackbar.type}> {snackbar.message}</Alert>
    </Snackbar>
  );
};

export const SnackbarWrapper = ({ children }) => {
  const [snackbar, setSnackbar] = useState(initialValues.snackbar);
  const handleClose = () =>
    setSnackbar({ open: false, message: '', type: AlertType.INFO });

  const showMessage = (message: string, type: AlertType = AlertType.INFO) => {
    setSnackbar({ open: true, message, type });
  };

  return (
    <SnackbarContext.Provider value={{ snackbar, handleClose, showMessage }}>
      <MuiSnackbar />
      {children}
    </SnackbarContext.Provider>
  );
};
