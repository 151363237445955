import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Skeleton from '@material-ui/lab/Skeleton';
import { useAppSelector } from '../../../store';
import TaskComments from './task-comments/TaskComments';

const Wrapper = styled.div`
  color: #fff;
  width: 60%;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Customer = styled.a`
  display: flex;
  align-items: center;
  font-weight: 600;

  span:last-child {
    margin-left: 8px;
  }

  .icon {
    font-size: 30px;
  }
`;

const Message = styled.p`
  margin-bottom: 48px;
  color: #98989c;
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
  border-radius: 12px;
`;

const ImageWrapper = styled.div`
  margin-right: 12px;
  width: 100%;
  height: 120px;
  position: relative;
  @media screen and (max-width: 600px) {
    height: 80px;
  }
`;

const Image = styled.img<{ isShow: boolean }>`
  object-fit: cover;
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: zoom-in;
  transition: opacity 0.3s ease;
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
`;

const MessageWrapper = styled.div`
  padding: 24px;
  min-width: 400px;
  background-color: #24242d;
  border-radius: 12px;
  /* margin-left: 28px; */
  margin-bottom: 24px;
  color: rgba(255, 255, 255, 0.8);

  @media screen and (max-width: 600px) {
    padding: 16px;
    min-width: 0px;
  }
`;

const MessageHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TaskDetails = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 460px) {
    grid-template-columns: 1fr;
  }
`;

const TaskDetail = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: rgb(25, 26, 32);
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;

  div {
    display: flex;
    align-items: left;
    flex-direction: column;
  }

  .icon {
    width: 30px;
    font-size: 24px;
    margin-right: 8px;
  }

  &.full-width {
    grid-column: 1 / -1;
  }
  
  .text {
    font-size: 12px;
    font-weight: 400;
  }
`;

const TaskDetailItem = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #999;
`;

const TaskInfo = styled.p`
  display: flex;
  align-items: center;
  font-weight: 600;

  span {
    font-size: 14px;
  }

  .icon {
    width: 30px;
    font-size: 24px;
    margin-right: 8px;
  }
`;

const TaskQuestions = styled.p`
  margin-bottom: 8px;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
`;

const PinnedPlant = styled.div`
  display: flex;
  height: 100px;
  margin: 25px 0px;

  div {
    font-size: 14px;
  }

  img {
    border-radius: 12px;
    margin-right: 25px;
  }
`;

const PinnedPlantItem = styled.div`
  font-weight: 400;
  color: #999999;

  span {
    color: #fff
  }
`;

const StyledSkeleton = styled(Skeleton)<{ isActive?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  transition: opacity 0.3s ease;

  @media screen and (max-width: 600px) {
    height: 80px;
  }

  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

const ImageComponent = ({ src, onClick }) => {
  const imageRef = useRef(null);
  const [skeleton, setSkeleton] = useState(true);

  useEffect(() => {
    const { complete } = imageRef.current;
    const handleLoad = () => {
      setSkeleton(false);
    };
    if (!complete) {
      imageRef.current.onload = handleLoad;
    } else {
      setSkeleton(false);
    }
  }, []);

  return (
    <ImageWrapper>
      <Image isShow={!skeleton} ref={imageRef} src={src} onClick={onClick} />
      <StyledSkeleton isActive={Boolean(skeleton)} variant="rect" />
    </ImageWrapper>
  );
};

const getMistingFrequency = (mistingFrequency) => {
  const formatMistingFrequency = (frequency) => {
    switch (frequency) {
      case 'Low':
        return 'Low (<40%)';
      case 'Moderate':
        return 'Moderate (40%-60%)';
      case 'High':
        return 'High (>60%)';
      case "Can't check":
        return "Can't check";
      default:
        return null;
    }
  };

  const formattedFrequency = formatMistingFrequency(mistingFrequency);

  if (formattedFrequency) {
    return {
      question: 'What is average humidity level in the area where you keep your plant?',
      value: formattedFrequency,
    };
  }

  return {
    question: 'How often do you mist your plant?',
    value: mistingFrequency,
  };
}

const getSunlightType = (sunlightType) => {
  const formatSunlightType = (sunlightType) => {
    switch (sunlightType) {
      case 'Full shade':
        return 'Shade';
      default:
        return null;
    }
  };

  const formattedSunlightType = formatSunlightType(sunlightType);

  if (formattedSunlightType) {
    return formattedSunlightType;
  }

  return sunlightType;
}

function TaskPopupContent({ task, isRestricted }) {
  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const auth = useAppSelector((state) => state.auth);

  const images = task?.images || [];

  const handleImageClick = (index) => () => {
    setPhotoIndex(index);
    setOpen(true);
  };

  const {
    plantName,
    location,
    message,
    plantAge,
    plantLocation,
    fertilizer,
    lastWater,
    lastRepot,
    questionType,
    isLocationChanged,
    feedback,
    isHelpful,
    temperatureChange,
    sunlightType,
    mistingFrequency,
    waterSplashOnLeaves,
    pestPresenceSigns,
    oldestLeavesSymptoms,
    pinnedPlant,
  } = task;

  return (
    <Wrapper>
      <MessageWrapper>
        <MessageHeader>
          {auth.isAdmin && (
            <Customer target="_blank" href={`mailto:${task.email}`}>
              <span className="icon">👩🏻‍🌾</span>
              <span>{task.email}</span>
            </Customer>
          )}
          {plantName && (
            <TaskInfo>
              <span className="icon">🌱</span>
              <span>{plantName}</span>
            </TaskInfo>
          )}
          {location && (
            <TaskInfo>
              <span className="icon">&#127757;</span>
              <span>{location}</span>
            </TaskInfo>
          )}
        </MessageHeader>
        {message && (
          <Message>
            {questionType && <TaskQuestions>{questionType}</TaskQuestions>}
            {message}
          </Message>
        )}
        {images.length > 0 && (
          <Images>
            {images.map((imageUrl, index) => (
              <ImageComponent
                key={imageUrl}
                src={imageUrl}
                onClick={handleImageClick(index)}
              />
            ))}
          </Images>
        )}
        {open && (
          <Lightbox
            imagePadding={20}
            animationDisabled
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => {
              setOpen(false);
            }}
            onMovePrevRequest={() => {
              setPhotoIndex(
                (prevIndex) => (prevIndex + images.length - 1) % images.length
              );
            }}
            onMoveNextRequest={() => {
              setPhotoIndex((prevIndex) => (prevIndex + 1) % images.length);
            }}
          />
        )}
        {pinnedPlant && (
          <PinnedPlant>
            <img
                src={pinnedPlant.imageUrl}
            />
            <div>
              <PinnedPlantItem>Pinned plant name: {<span>{pinnedPlant.name}</span>}</PinnedPlantItem>
              <br/>
              <PinnedPlantItem>Pinned plant latin: {<span>{pinnedPlant.latin}</span>}</PinnedPlantItem>
            </div>
          </PinnedPlant>
        )}
        <TaskDetails>
          {plantAge && (
            <TaskDetail>
              <span className="icon">&#127874;</span>
              <div>
                <TaskDetailItem>Plant Age</TaskDetailItem>
                <span>{plantAge}</span>
              </div>
            </TaskDetail>
          )}
          {plantLocation && (
            <TaskDetail>
              <span className="icon">&#127969;</span>
              <div>
                <TaskDetailItem>Plant Location</TaskDetailItem>
                <span>{plantLocation}</span>
              </div>
            </TaskDetail>
          )}
          {fertilizer && (
            <TaskDetail>
              <span className="icon">&#127807;</span>
              <div>
                <TaskDetailItem>How often do you use a plant-specific fertilizer?</TaskDetailItem>
                <span>{fertilizer}</span>
              </div>
            </TaskDetail>
          )}
          {lastWater && (
            <TaskDetail>
              <span className="icon">&#128167;</span>
              <div>
                <TaskDetailItem>How often do you water this plant?</TaskDetailItem>
                <span>{lastWater}</span>
              </div>
            </TaskDetail>
          )}
          {lastRepot && (
            <TaskDetail>
              <span className="icon">&#129716;</span>
              <div>
                <TaskDetailItem>When was the last time you changed your plant soil?</TaskDetailItem>
                <span>{lastRepot}</span>
              </div>
            </TaskDetail>
          )}
          {typeof isLocationChanged === 'boolean' && (
            <TaskDetail>
              <span className="icon">&#129716;</span>
              <div>
                <TaskDetailItem>Have you moved your plant to another location within the last month?</TaskDetailItem>
                <span>{isLocationChanged ? 'Yes' : 'No'}</span>
              </div>
            </TaskDetail>
          )}
          {temperatureChange && (
            <TaskDetail>
              <span className="icon">&#127777;</span>
              <div>
                <TaskDetailItem>Has there been a noticeable temperature change lately?</TaskDetailItem>
                <span>{temperatureChange}</span>
              </div>
            </TaskDetail>
          )}
          {sunlightType && (() => {
            const value = getSunlightType(sunlightType);

            return (
              <TaskDetail>
                <span className="icon">&#9728;</span>
                <div>
                  <TaskDetailItem>What type of sunlight your plant gets?</TaskDetailItem>
                  <span>{value}</span>
                </div>
              </TaskDetail>
            );
          })()}
          {mistingFrequency && (() => {
            const { question, value } = getMistingFrequency(mistingFrequency);

            return (
              <TaskDetail>
                <span className="icon">&#128166;</span>
                <div>
                  <TaskDetailItem>{question}</TaskDetailItem>
                  <span>{value}</span>
                </div>
              </TaskDetail>
            );
          })()}
          {waterSplashOnLeaves && (
            <TaskDetail>
              <span className="icon">&#127807;</span>
              <div>
                <TaskDetailItem>Does the water splash on the leaves during watering sessions?</TaskDetailItem>
                <span>{waterSplashOnLeaves}</span>
              </div>
            </TaskDetail>
          )}
          {pestPresenceSigns && (
            <TaskDetail>
              <span className="icon">&#128027;</span>
              <div>
                <TaskDetailItem>Are there any signs of pest presence?</TaskDetailItem>
                <span>{pestPresenceSigns}</span>
              </div>
            </TaskDetail>
          )}
          {oldestLeavesSymptoms && (
            <TaskDetail>
              <span className="icon">&#127810;</span>
              <div>
                <TaskDetailItem>Do the symptoms appear on the oldest leaves only?</TaskDetailItem>
                <span>{oldestLeavesSymptoms}</span>
              </div>
            </TaskDetail>
          )}
          {(typeof isHelpful === 'boolean' || feedback?.length) && (
            <TaskDetail className="full-width">
              <span className="icon">&#128172;</span>
              <div>
                {typeof isHelpful === 'boolean' &&
                  <>
                  <TaskDetailItem>Helpfull</TaskDetailItem>
                  <span>{isHelpful ? 'Yes' : 'No'}</span>
                  </>
                }
                {feedback?.length &&
                  <>
                    <TaskDetailItem>Feedback</TaskDetailItem>
                    <p className="text">{ feedback }</p>
                  </>
                }
              </div>
            </TaskDetail>
          )}
        </TaskDetails>
      </MessageWrapper>
      {!isRestricted && <TaskComments task={task} />}
    </Wrapper>
  );
}

export default TaskPopupContent;
