import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { wsConnect } from './store/middlewares/websocket';

import Layout from './components/common/layout/Layout';
import TaskBoard from './components/task-board/TaskBoard';
import UserBoard from './components/user-board/UserBoard';
import Login from './components/login/Login';
import Archive from './components/archive/Archive';
import { useAppDispatch } from './store';
import ModalManager from './components/common/modals/ModalManager';
import { initAuthAsync } from './store/slices/authSlice';
import { fetchAllTasksAsync } from './store/slices/taskboardSlice';
import AnimatedAvocado from './components/common/lottie-animations/AnimatedAvocado';
import Users from './components/users/Users';
import { SnackbarWrapper } from './components/snackbar';

const Wrapper = styled.div`
  position: relative;
`;

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #2a2d39;
  z-index: 10000;
  transition: opacity 0.4s linear;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Message = styled.p`
  margin-top: -32px;
  font-size: 20px;
`;

function App() {
  const dispatch = useAppDispatch();
  const [loaded, setLoaded] = useState(false);
  const [authready, setAuthReady] = useState(false);

  useEffect(() => {
    dispatch(wsConnect());
  }, [dispatch]);

  useEffect(() => {
    const initAuth = async () => {
      const authResult = await dispatch(initAuthAsync());
      if (authResult.meta.requestStatus === 'fulfilled') {
        await dispatch(fetchAllTasksAsync());
        setAuthReady(true);
      }
    };
    initAuth();
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('load', (event) => {
      setLoaded(true);
    });
  }, [dispatch]);

  return (
    <Wrapper>
      <SnackbarWrapper>
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/settings">
              <div>settings</div>
            </Route>
            <Route path="/user/:id">
              <Layout>
                <UserBoard />
              </Layout>
            </Route>
            <Route path="/archive">
              <Layout>
                <Archive />
              </Layout>
            </Route>
            <Route path="/users">
              <Layout>
                <Users />
              </Layout>
            </Route>
            <Route path="/">
              <Layout>
                <TaskBoard />
              </Layout>
            </Route>
          </Switch>
          <ModalManager />
        </Router>
      </SnackbarWrapper>
      <AnimatePresence initial={false}>
        {!loaded && !authready && (
          <Overlay
            key="overlay"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.6, duration: 0.3, ease: 'linear' }}
          >
            <LoaderContainer>
              <AnimatedAvocado />
              <Message>Loading...</Message>
            </LoaderContainer>
          </Overlay>
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

export default App;
