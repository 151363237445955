/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AssignAuthor from './AssignAuthor';
import UploadPdf from './UploadPdf';
import UploadedPdf from './UploadedPdf';
import { useAppDispatch, useAppSelector } from '../../../store';
import { DEFAULT_AVATAR_PLACEHOLDER } from '../../../lib/services/images';
import PendingActions from './PendingActions';
import SwapIcon from '../../common/icons/SwapIcon';
import { openModal } from '../../../store/slices/uiSlice';
import DocumentSection from './DocumentSection';
import ReloadIcon from '../../common/icons/ReloadIcon';
import { assignProofReaderAsync } from '../../../store/slices/taskboardSlice';

const Wrapper = styled.div`
  margin-left: 64px;
  flex-grow: 1;
  min-width: 300px;
  max-width: 300px;
  margin-bottom: 48px;

  @media screen and (max-width: 600px) {
    margin-left: 0;
    max-width: 100%;
    width: 100%;
  }
`;

const Toolbar = styled.div`
  border-bottom: 1px solid #444650;
  padding-bottom: 24px;
`;

const SectionTitle = styled.h3`
  color: #979797;
  font-weight: 600;
  margin-bottom: 12px;
  line-height: 1;
  font-size: 14px;
  text-transform: uppercase;
`;

const StatusIndicator = styled.div<{ color?: string }>`
  display: inline-block;
  padding: 4px 14px;
  text-transform: uppercase;
  border-radius: 32px;
  font-weight: 500;
  margin-bottom: 32px;
  color: #fff;
  transition: background-color 0.4s ease;
  background-color: ${({ color }) => color};
`;

const colorByStatus = {
  0: '#7F6FFF',
  1: '#3C8C8A',
  2: '#925CB0',
  3: '#b9375e',
  4: '#448ACA',
  5: '#448ACA',
};

const statusTitle = {
  0: 'to do',
  1: 'in progress',
  2: 'ready to send',
  3: 'finished',
  4: 'in revision',
  5: 'after revision',
};

const userStatusTitle = {
  0: 'to do',
  1: 'to do',
  2: 'approved',
  3: 'finished',
  4: 'in revision',
  5: 'after revision',
};

const TaskTimeline = styled.ul`
  display: flex;
  flex-direction: column;
  /* margin-bottom: 32px; */
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  font-size: 14px;

  :not(:last-child) {
    margin-bottom: 18px;
  }

  p {
    white-space: nowrap;
  }
`;
const Circle = styled.div<{ color: string; line?: boolean }>`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  border-radius: 50%;
  position: relative;
  background-color: ${({ color }) => color};
`;

const Executor = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  > p {
    color: #979797;
    font-weight: 500;
    margin-bottom: 12px;
  }
`;
const ExecutorInfo = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 6px 12px 6px 6px;
  border-radius: 8px;
  align-items: center;
  transition: 0.2s ease;

  :hover {
    background: #24242e;
  }
`;
const Name = styled.p`
  font-size: 14px;
  font-weight: 500;
`;
const Avatar = styled.img`
  object-fit: cover;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 8px;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 0;
  flex-shrink: 0;
`;

const Actions = styled.div``;

function TaskPopupSidebar({ task, handleClose }) {
  const auth = useAppSelector((state) => state.auth);
  const users = useAppSelector((state) => state.users);
  const executor = task.executor || users.find((user) => user._id === task._id);
  const proofreader = users.find(
    (user) =>
      user._id === task.proofreader ||
      user._id === (task.proofreader as any)?._id
  );

  const dispatch = useAppDispatch();

  const handleChangeAuthorClick = () => {
    dispatch(openModal({ type: 'change-author', props: { taskId: task._id } }));
  };

  const handleChangeProofreader = () => {
    dispatch(
      openModal({ type: 'change-proofreader', props: { taskId: task._id } })
    );
  };

  const handleUnassignProofreader = async () => {
    await dispatch(
      assignProofReaderAsync({
        taskId: task._id,
        userId: null,
      })
    );
  };

  const renderActions = () => {
    if (task.isPending) {
      return <PendingActions taskId={task._id} isAdmin={auth.isAdmin} />;
    }
    if (task.status === 0) {
      return <AssignAuthor taskId={task._id} />;
    }
    if (task.status > 0) {
      return (
        <>
          <DocumentSection task={task} />
          {task.status === 2 && auth.isAdmin && <UploadPdf task={task} />}
          {task.status === 3 && <UploadedPdf task={task} />}
        </>
      );
    }
  };

  const renderStatus = () => {
    if (task.isPending) {
      return 'Pending';
    }
    if (auth.isAdmin) {
      return statusTitle[task.status];
    }
    if (task.status === 1 && task.docUrl) {
      return 'in review';
    }

    return userStatusTitle[task.status];
  };

  return (
    <Wrapper>
      <Toolbar>
        <SectionTitle>Status</SectionTitle>
        <StatusIndicator
          color={task.isPending ? '#d69f4c' : colorByStatus[task.status]}
        >
          {renderStatus()}
        </StatusIndicator>
        <SectionTitle>History</SectionTitle>
        <TaskTimeline>
          {task.ratedAt && (
            <Item>
              <Circle color={colorByStatus[3]} />
              <p>Rated {dayjs(task.ratedAt).format('DD.MM.YY, HH:mm ')}</p>
            </Item>
          )}
          {task.sendedAt && (
            <Item>
              <Circle color={colorByStatus[3]} />
              <p>Finished {dayjs(task.sendedAt).format('DD.MM.YY, HH:mm ')}</p>
            </Item>
          )}
          {task.approvedAt && (
            <Item>
              <Circle color={colorByStatus[2]} />
              <p>
                Approved {dayjs(task.approvedAt).format('DD.MM.YY, HH:mm ')}
              </p>
            </Item>
          )}
          {((task.revisedAt && !task.revisionAt) ||
            task.revisedAt > task.revisionAt) && (
            <Item>
              <Circle color={colorByStatus[4]} />
              <p>Revised {dayjs(task.revisedAt).format('DD.MM.YY, HH:mm ')}</p>
            </Item>
          )}
          {task.revisionAt && (
            <Item>
              <Circle color={colorByStatus[4]} />
              <p>
                Revision {dayjs(task.revisionAt).format('DD.MM.YY, HH:mm ')}
              </p>
            </Item>
          )}
          {task.untakenAt && (
            <Item>
              <Circle color={colorByStatus[4]} />
              <p>Untaken {dayjs(task.untakenAt).format('DD.MM.YY, HH:mm ')}</p>
            </Item>
          )}
          {task.takenAt && (
            <Item>
              <Circle color={colorByStatus[4]} />
              <p>Taken {dayjs(task.takenAt).format('DD.MM.YY, HH:mm ')}</p>
            </Item>
          )}
          {((task.attachedAt && !task.unattachedAt) ||
            task.attachedAt > task.unattachedAt) && (
            <Item>
              <Circle color={colorByStatus[4]} />
              <p>
                Attached {dayjs(task.attachedAt).format('DD.MM.YY, HH:mm ')}
              </p>
            </Item>
          )}
          {task.unattachedAt && (
            <Item>
              <Circle color={colorByStatus[4]} />
              <p>
                Unattached {dayjs(task.unattachedAt).format('DD.MM.YY, HH:mm ')}
              </p>
            </Item>
          )}
          {task.reassignedAt && (
            <Item>
              <Circle color={colorByStatus[1]} />
              <p>
                Reassigned {dayjs(task.reassignedAt).format('DD.MM.YY, HH:mm ')}
              </p>
            </Item>
          )}
          {task.assignedAt && (
            <Item>
              <Circle color={colorByStatus[1]} />
              <p>
                Assigned {dayjs(task.assignedAt).format('DD.MM.YY, HH:mm ')}
              </p>
            </Item>
          )}
          {task.createdAt && (
            <Item>
              <Circle color={colorByStatus[0]} />
              <p>Created {dayjs(task.createdAt).format('DD.MM.YY, HH:mm ')}</p>
            </Item>
          )}
        </TaskTimeline>

        {task.executor && executor && (
          <>
            <SectionTitle style={{ marginTop: '32px' }}>Author</SectionTitle>
            <Executor>
              <Link onClick={handleClose} to={`/user/${executor._id}`}>
                <ExecutorInfo>
                  <Avatar src={executor.avatar || DEFAULT_AVATAR_PLACEHOLDER} />
                  <Name>
                    {executor.firstName} {executor.lastName}
                  </Name>
                </ExecutorInfo>
              </Link>

              {auth.isAdmin && (task.status < 2 || task.status === 4) && (
                <Tooltip title="Change author">
                  <StyledIconButton onClick={handleChangeAuthorClick}>
                    <SwapIcon />
                  </StyledIconButton>
                </Tooltip>
              )}
            </Executor>
          </>
        )}
        {proofreader && (
          <>
            <SectionTitle style={{ marginTop: '32px' }}>
              Proofreader
            </SectionTitle>
            <Executor>
              <Link onClick={handleClose} to={`/user/${proofreader._id}`}>
                <ExecutorInfo>
                  <Avatar
                    src={proofreader.avatar || DEFAULT_AVATAR_PLACEHOLDER}
                  />
                  <Name>
                    {proofreader.firstName} {proofreader.lastName}
                  </Name>
                </ExecutorInfo>
              </Link>

              {auth.isAdmin && (
                <div>
                  <Tooltip title="Unassign proofreader">
                    <StyledIconButton onClick={handleUnassignProofreader}>
                      <ReloadIcon />
                    </StyledIconButton>
                  </Tooltip>
                  <Tooltip title="Change proofreader">
                    <StyledIconButton onClick={handleChangeProofreader}>
                      <SwapIcon />
                    </StyledIconButton>
                  </Tooltip>
                </div>
              )}
            </Executor>
          </>
        )}
      </Toolbar>
      <Actions>{renderActions()}</Actions>
    </Wrapper>
  );
}

export default TaskPopupSidebar;
