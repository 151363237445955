/* eslint-disable no-param-reassign */
import {
  CaseReducer,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios from '../../lib/api/axios';

export interface Comment {
  _id: string;
  text: string;
  createdAt: string;
  updatedAt: string;
  author: {
    _id: string;
    avatar: string;
    firstName: string;
    lastName?: string;
  };
}

export interface Task {
  _id: string;
  requestId: string;
  email: string;
  message: string;
  status: number;
  createdAt: Date;
  docUrl?: string;
  images?: string[];
  approvedAt: Date;
  finishedAt: Date;
  paid: boolean;
  executor: {
    firstName: string;
    lastName?: string;
    avatar: string;
    _id: string;
  };
  proofreader: string;
  isPending?: boolean;
  pendedAt?: Date;
  comments?: [Comment];
  plantName?: string;
  plantAge: string;
  lastRepot: string;
  lastWater: string;
  fertilizer: string;
  isLocationChanged?: boolean;
  temperatureChange?: string;
  sunlightType?: string;
  mistingFrequency?: string;
  waterSplashOnLeaves?: string;
  pestPresenceSigns?: string;
  oldestLeavesSymptoms?: string;

  docPlantDisease: string;
  docPlantLocation: string;
  docPlantName: string;

  proofReaderAt: Date;
  takenAt: Date;
}

export type Column = {
  id: 0 | 1 | 2 | 4;
  title: string;
};

export type ColumnsOrder = ['to-do', 'in-progress', 'in-review'];

interface TaskBoardState {
  tasks: { [key: string]: Task };
  archive: Task[];
  userTasks: { [key: string]: Task[] };
  columns: Column[];
}

const initialState: TaskBoardState = {
  tasks: {},
  archive: [],
  userTasks: {},
  columns: [
    { id: 0, title: 'To do' },
    {
      id: 1,
      title: 'In progress',
    },
    { id: 2, title: 'Ready to send' },
    { id: 4, title: 'In Revision' },
  ],
};

type CreateNewTasklPayload = Task;

const CREATE_NEW_TASK: CaseReducer<
  TaskBoardState,
  PayloadAction<CreateNewTasklPayload>
> = (state, action) => {
  state.tasks[action.payload._id] = action.payload;
};

type UpdateTaskPayments = Task;

const UPDATE_TASK_PAYMENTS: CaseReducer<
  TaskBoardState,
  PayloadAction<UpdateTaskPayments>
> = (state, action) => {
  state.tasks[action.payload._id] = action.payload;
};

type AttachDocumentViaSocketPayload = Task;

const ATTACH_DOCUMENT_VIA_COSKET: CaseReducer<
  TaskBoardState,
  PayloadAction<AttachDocumentViaSocketPayload>
> = (state, action) => {
  state.tasks[action.payload._id] = action.payload;
};

const ASSIGN_DOCUMENT_VIA_COSKET: CaseReducer<
  TaskBoardState,
  PayloadAction<AttachDocumentViaSocketPayload>
> = (state, action) => {
  state.tasks[action.payload._id] = action.payload;
};

const ASSIGN_PROOFREADER_VIA_COSKET: CaseReducer<
  TaskBoardState,
  PayloadAction<AttachDocumentViaSocketPayload>
> = (state, action) => {
  state.tasks[action.payload._id] = action.payload;
};

type ApproveDocumentViaSocketPayload = Task;

const APPROVE_TASK_VIA_COSKET: CaseReducer<
  TaskBoardState,
  PayloadAction<ApproveDocumentViaSocketPayload>
> = (state, action) => {
  state.tasks[action.payload._id] = action.payload;
};

type SetOnRevisionViaSocketPayload = Task;

const SET_ON_REVISION_VIA_COSKET: CaseReducer<
  TaskBoardState,
  PayloadAction<SetOnRevisionViaSocketPayload>
> = (state, action) => {
  state.tasks[action.payload._id] = action.payload;
};

type PendTaskViaSocketPayload = { taskId: string };

const PEND_TASK_VIA_COSKET: CaseReducer<
  TaskBoardState,
  PayloadAction<PendTaskViaSocketPayload>
> = (state, action) => {
  state.tasks[action.payload.taskId].isPending = true;
};

const UNPEND_TASK_VIA_COSKET: CaseReducer<
  TaskBoardState,
  PayloadAction<PendTaskViaSocketPayload>
> = (state, action) => {
  state.tasks[action.payload.taskId].isPending = false;
};

type FetchAllTasksReturnData = { [key: string]: Task };

type FetchAllTasksPayload = void;
export type FetchArchiveTasksPayload = {
  proofreader?: string;
  executor?: string;
  startTime: number;
  endTime: number;
};
type FetchArchiveTasksReturnData = Task[];

interface ErrorResponse {
  status: number;
  message: string;
}

interface FetchAllTasksThunkApi {
  rejectValue: ErrorResponse;
}

export const fetchAllTasksAsync = createAsyncThunk<
  FetchAllTasksReturnData,
  FetchAllTasksPayload,
  FetchAllTasksThunkApi
>('taskboard/fetchAllTasks', async (_payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'get',
      url: '/task',
    });

    const tasks = {};
    const tasksById = { 0: [], 1: [], 2: [] };

    response.data.data.forEach((task) => {
      tasks[task._id] = task;
      if (task.status > 2) {
        return null;
      }
      tasksById[task.status].push(task._id);
    });
    return tasks;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const fetchArchiveTasksAsync = createAsyncThunk<
  FetchArchiveTasksReturnData,
  FetchArchiveTasksPayload,
  FetchAllTasksThunkApi
>('taskboard/fetchArchiveTasks', async (_payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'get',
      url: '/task/archive',
      params: {
        proofreader: _payload.proofreader,
        executor: _payload.executor,
        startTime: _payload.startTime,
        endTime: _payload.endTime,
      },
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type FetchUserTasksPayload = {
  executor?: string;
};
type FetchUserTasksReturnData = {
  tasks: Task[];
  executor: string;
};

export const fetchUserTasksAsync = createAsyncThunk<
  FetchUserTasksReturnData,
  FetchUserTasksPayload,
  FetchAllTasksThunkApi
>('taskboard/fetchUserTasks', async (_payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'get',
      url: '/task/archive',
      params: {
        executor: _payload.executor,
        status: 'all',
      },
    });

    return { tasks: response.data.data, executor: _payload.executor };
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type AssignExecutorReturnData = Task;

type AssignExecutorPayload = {
  taskId: string;
  userId: string;
};

interface AssignExecutorThunkApi {
  rejectValue: ErrorResponse;
}

export const assignExecutorAsync = createAsyncThunk<
  AssignExecutorReturnData,
  AssignExecutorPayload,
  AssignExecutorThunkApi
>('taskboard/assignExecutor', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'put',
      url: '/task/assign',
      data: { taskId: payload.taskId, userId: payload.userId },
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type AssignProofreaderReturnData = Task;

type AssignProofreaderPayload = {
  taskId: string;
  userId: string;
};

interface AssignProofreaderThunkApi {
  rejectValue: ErrorResponse;
}

export const assignProofReaderAsync = createAsyncThunk<
  AssignProofreaderReturnData,
  AssignProofreaderPayload,
  AssignProofreaderThunkApi
>('taskboard/assignProofreader', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'put',
      url: '/task/assign-proffreader',
      data: { taskId: payload.taskId, userId: payload.userId },
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const takeProofReaderAsync = createAsyncThunk<
  AssignProofreaderReturnData,
  AssignProofreaderPayload,
  AssignProofreaderThunkApi
>('taskboard/takeProofreader', async (payload, { rejectWithValue,  }) => {
  try {
    const response = await axios({
      method: 'put',
      url: '/task/take-proofreader',
      data: { taskId: payload.taskId, userId: payload.userId },
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response?.data)
  }
});

type AttachDocumentReturnData = Task;

type AttachDocumentPayload = {
  taskId: string;
  docUrl: string;
};

type AttachFullDocumentPayload = {
  taskId: string;
  docUrl: string;
  disease: string;
  location: string;
  plant: string;
};

interface AttachDocumentThunkApi {
  rejectValue: ErrorResponse;
}

export const attachDocumentAsync = createAsyncThunk<
  AttachDocumentReturnData,
  AttachDocumentPayload,
  AttachDocumentThunkApi
>('taskboard/attachDocument', async (payload, { rejectWithValue }) => {
  const { taskId, docUrl } = payload;
  try {
    const response = await axios({
      method: 'put',
      url: '/task/attach',
      data: { taskId, docUrl },
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const attachFullDocumentAsync = createAsyncThunk<
  AttachDocumentReturnData,
  AttachFullDocumentPayload,
  AttachDocumentThunkApi
>('taskboard/attachFullDocument', async (payload, { rejectWithValue }) => {
  const { taskId, docUrl, disease, location, plant } = payload;

  try {
    const response = await axios({
      method: 'put',
      url: '/task/attach',
      data: { taskId, docUrl, disease, location, plant },
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type ApproveTaskReturnData = Task;

type ApproveTaskPayload = {
  taskId: string;
};

interface ApproveTaskThunkApi {
  rejectValue: ErrorResponse;
}

export const approveTaskAsync = createAsyncThunk<
  ApproveTaskReturnData,
  ApproveTaskPayload,
  ApproveTaskThunkApi
>('taskboard/approveTask', async (payload, { rejectWithValue }) => {
  const { taskId } = payload;
  try {
    const response = await axios({
      method: 'put',
      url: '/task/approve',
      data: { taskId },
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type RevisionTaskReturnData = Task;

type RevisionTaskPayload = {
  taskId: string;
};

interface RevisionTaskThunkApi {
  rejectValue: ErrorResponse;
}

export const setTaskOnRevisionAsync = createAsyncThunk<
  RevisionTaskReturnData,
  RevisionTaskPayload,
  RevisionTaskThunkApi
>('taskboard/revisionTask', async (payload, { rejectWithValue }) => {
  const { taskId } = payload;
  try {
    const response = await axios({
      method: 'post',
      url: '/task/revision',
      data: { taskId },
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type AttachResponseReturnData = Task;

type AttachResponsePayload = {
  taskId: string;
  pdfFile: File;
};

export const attachResponseAsync = createAsyncThunk<
  AttachResponseReturnData,
  AttachResponsePayload,
  ApproveTaskThunkApi
>('taskboard/attachResponse', async (payload, { rejectWithValue }) => {
  const { pdfFile, taskId } = payload;

  const formData = new FormData();
  formData.append('taskId', payload.taskId);
  formData.append('comment', 'Some comment to customer');
  formData.append('pdfFile', payload.pdfFile);

  try {
    const response = await axios({
      method: 'put',
      url: '/task/response',
      data: formData,
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type SendResponseReturnData = Task;

type SendResponsePayload = {
  taskId: string;
  pdf: File;
  comment: string;
};

interface SendTaskThunkApi {
  rejectValue: ErrorResponse;
}

export const sendResponseAsync = createAsyncThunk<
  SendResponseReturnData,
  SendResponsePayload,
  SendTaskThunkApi
>('taskboard/send', async (payload, { rejectWithValue }) => {
  const formData = new FormData();
  formData.append('taskId', payload.taskId);
  formData.append('comment', payload.comment);
  formData.append('pdfFile', payload.pdf);
  try {
    const response = await axios({
      method: 'put',
      url: '/task/send',
      data: formData,
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type PendTaskReturnData = string;

type PendTaskPayload = {
  taskId: string;
};

export const pendTaskAsync = createAsyncThunk<
  PendTaskReturnData,
  PendTaskPayload,
  SendTaskThunkApi
>('taskboard/pend', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'put',
      url: `/task/${payload.taskId}/pend`,
    });

    return response.data.id;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

export const restoreTaskAsync = createAsyncThunk<
  PendTaskReturnData,
  PendTaskPayload,
  SendTaskThunkApi
>('taskboard/restore', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'put',
      url: `/task/${payload.taskId}/restore`,
    });

    return response.data.id;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type DeleteTaskReturnData = string;

type DeleteTaskPayload = {
  taskId: string;
};

export const deleteTaskAsync = createAsyncThunk<
  DeleteTaskReturnData,
  DeleteTaskPayload,
  SendTaskThunkApi
>('taskboard/delete', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `/task/${payload.taskId}/delete`,
    });

    return response.data.id;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type CreateCommentReturnData = Task;

type CreateCommentPayload = {
  text: string;
  taskId: string;
};

export const createCommentAsync = createAsyncThunk<
  CreateCommentReturnData,
  CreateCommentPayload,
  SendTaskThunkApi
>(
  'taskboard/create-comment',
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'post',
        url: `/task/${payload.taskId}/comment`,
        data: { text: payload.text },
      });

      return response.data.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

type UpdateCommentReturnData = Task;

type UpdateCommentPayload = {
  text: string;
  taskId: string;
  commentId: string;
};

export const updateCommentAsync = createAsyncThunk<
  UpdateCommentReturnData,
  UpdateCommentPayload,
  SendTaskThunkApi
>('taskboard/update-comment', async (payload, { rejectWithValue }) => {
  const { text, taskId, commentId } = payload;
  try {
    const response = await axios({
      method: 'put',
      url: `/task/${taskId}/comment/${commentId}`,
      data: { text },
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type DeleteCommentReturnData = Task;

type DeleteCommentPayload = {
  taskId: string;
  commentId: string;
};

export const deleteCommentAsync = createAsyncThunk<
  DeleteCommentReturnData,
  DeleteCommentPayload,
  SendTaskThunkApi
>('taskboard/delete-comment', async (payload, { rejectWithValue }) => {
  const { taskId, commentId } = payload;
  try {
    const response = await axios({
      method: 'delete',
      url: `/task/${taskId}/comment/${commentId}`,
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

const taskboardSlice = createSlice({
  name: 'taskboard',
  initialState,
  reducers: {
    createNewTask: CREATE_NEW_TASK,
    attachDocumentViaSocket: ATTACH_DOCUMENT_VIA_COSKET,
    assignTasktViaSocket: ASSIGN_DOCUMENT_VIA_COSKET,
    assignProofReaderViaSocket: ASSIGN_PROOFREADER_VIA_COSKET,
    approveTasktViaSocket: APPROVE_TASK_VIA_COSKET,
    setOnRevisionViaSocket: SET_ON_REVISION_VIA_COSKET,
    updateTaskPayments: UPDATE_TASK_PAYMENTS,
    pendTaskViaSocket: PEND_TASK_VIA_COSKET,
    unpendTaskViaSocket: UNPEND_TASK_VIA_COSKET,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllTasksAsync.fulfilled, (state, { payload }) => {
      state.tasks = payload;
    });
    builder.addCase(fetchArchiveTasksAsync.fulfilled, (state, { payload }) => {
      state.archive = payload;
    });
    builder.addCase(fetchUserTasksAsync.fulfilled, (state, { payload }) => {
      state.userTasks[payload.executor] = payload.tasks;
    });
    builder.addCase(assignExecutorAsync.fulfilled, (state, { payload }) => {
      state.tasks[payload._id] = payload;
    });
    builder.addCase(assignProofReaderAsync.fulfilled, (state, { payload }) => {
      state.tasks[payload._id] = payload;
    });
    builder.addCase(attachDocumentAsync.fulfilled, (state, { payload }) => {
      state.tasks[payload._id] = payload;
    });
    builder.addCase(setTaskOnRevisionAsync.fulfilled, (state, { payload }) => {
      state.tasks[payload._id] = payload;
    });
    builder.addCase(approveTaskAsync.fulfilled, (state, { payload }) => {
      state.tasks[payload._id] = payload;
    });
    builder.addCase(attachResponseAsync.fulfilled, (state, { payload }) => {
      state.tasks[payload._id] = payload;
    });
    builder.addCase(sendResponseAsync.fulfilled, (state, { payload }) => {
      state.tasks[payload._id] = payload;
    });
    builder.addCase(pendTaskAsync.fulfilled, (state, { payload }) => {
      state.tasks[payload].isPending = true;
    });
    builder.addCase(restoreTaskAsync.fulfilled, (state, { payload }) => {
      state.tasks[payload].isPending = false;
    });
    builder.addCase(deleteTaskAsync.fulfilled, (state, { payload }) => {
      delete state.tasks[payload];
    });
    builder.addCase(createCommentAsync.fulfilled, (state, { payload }) => {
      state.tasks[payload._id].comments = payload.comments;
    });
    builder.addCase(updateCommentAsync.fulfilled, (state, { payload }) => {
      state.tasks[payload._id].comments = payload.comments;
    });
    builder.addCase(deleteCommentAsync.fulfilled, (state, { payload }) => {
      state.tasks[payload._id].comments = payload.comments;
    });
  },
});

export const {
  createNewTask,
  updateTaskPayments,
  attachDocumentViaSocket,
  assignTasktViaSocket,
  assignProofReaderViaSocket,
  approveTasktViaSocket,
  setOnRevisionViaSocket,
  pendTaskViaSocket,
  unpendTaskViaSocket,
} = taskboardSlice.actions;

export default taskboardSlice.reducer;
